<template>
  <div class="project-new">
    <pageHeaderView></pageHeaderView>
    <Form ref="projectNewForm" :model="project" :rules="ruleValidate" :label-width="140">
    <Card>
      <p slot="title">项目信息</p>
      <Row>
        <Col span="11" class="left">
          <Form-item label="项目名称" prop="Name">
            <Input v-model="project.Name" placeholder="请输入项目名称"></Input>
          </Form-item>
          <Form-item label="工程状态" prop="Status">
            <Select v-model="project.Status" placeholder="请选择工程状态">
              <Option :value="0">计划</Option>
              <Option :value="1">在建</Option>
              <Option :value="2">竣工</Option>
              <Option :value="3">停工</Option>
            </Select>
          </Form-item>
          <Form-item label="项目类型" prop="Type">
            <Select v-model="project.Type" placeholder="请选择项目类型">
              <Option :value="1">房屋建筑工程</Option>
              <Option :value="2">其他工程</Option>
            </Select>
          </Form-item>
          <Form-item label='项目区域' prop="AreaId">
            <!-- <Select v-model='project.AreaId' placeholder="请选择城市" style='width:80px'>
              <Option v-for='item,index in CityList' :value="item.value" key={index}>{{item.city}}</Option>
            </Select> -->
            <city-select-list @selectCity="selectCity"></city-select-list>
          </Form-item>
          <Form-item label="项目地址" prop="Address">
            <Input v-model="project.Address" placeholder="请输入项目地址"></Input>
          </Form-item>
          <Form-item label="项目地理坐标" prop="PrjCoordinate">
            纬度：<InputNumber v-model="project.Latitude" placeholder="纬度坐标" style="width:100px"></InputNumber>
            <span class="font-grey small">&nbsp;例34.799770</span><br>
            经度：<InputNumber v-model="project.Longitude" placeholder="经度坐标" style="width:100px"></InputNumber>
            <span class="font-grey small">&nbsp;例113.660720</span><br>
            [{{project.Longitude}},{{project.Latitude}}]
            <Button @click="showMapPicker=true" type="primary" icon="ios-pin">地图选择点</Button>
          </Form-item>
          <Form-item label="项目标段" prop="Tender">
            <Input v-model="project.Tender" placeholder="请输入项目标段"></Input>
          </Form-item>
          <Form-item label="项目管理员" prop="ProjectAdminId">
            {{ project.ProjectAdminName }}
            <Button type="primary" @click="toggleAdminSelection">选择项目管理员</Button>
          </Form-item>
        </Col>
        <Col span="11" offset="1" class="right">
          <Form-item label="项目备案编号" prop="RegistrationNumber">
            <Input v-model="project.RegistrationNumber" placeholder="请输入项目备案编号"></Input>
          </Form-item>
          <Form-item label="施工许可证号" prop="ConstructionPermitNumber">
            <Input v-model="project.ConstructionPermitNumber" placeholder="请输入施工许可证号"></Input>
          </Form-item>
          <Form-item label="工资专户账号" prop="BankAccountNumber">
            <Input v-model="project.BankAccountNumber" placeholder="请输入工资专户账号"></Input>
          </Form-item>
          <Form-item label="工资专户开户行" prop="BankName">
            <Input v-model="project.BankName" placeholder="请输入工资专户开户行"></Input>
          </Form-item>
          <Form-item label="项目联系人" prop="PrjContactName">
            <Input v-model="project.PrjContactName" placeholder="请输入项目联系人"></Input>
          </Form-item>
          <Form-item label="联系人电话" prop="PrjContactNumber">
            <Input v-model="project.PrjContactNumber" placeholder="请输入项目电话"></Input>
          </Form-item>
          <Form-item label="计划开工日期" prop="PlannedStartDate">
            <Date-picker type="date" placeholder="选择日期" :value="project.PlannedStartDate" @on-change="setPlannedStartDate"></Date-picker>
          </Form-item>
          <Form-item label="计划竣工日期" prop="PlannedCompletionDate">
            <Date-picker type="date" placeholder="选择日期" :value="project.PlannedCompletionDate" @on-change="setPlannedCompletionDate"></Date-picker>
          </Form-item>
        </Col>
      </Row>
    </Card>
    <div class="padding-top-20"></div>
    <Card>
      <p slot="title">项目主体信息</p>
      <!-- 建设单位 -->
      <div class="company-item">
        <Row>
          <Col span="24">
            <Form-item label="主体类型">
              <Select v-model="project.Companies[0].Type" style="width:240px;" placeholder="建设单位" disabled>
                <Option :value="1">建设单位</Option>
                <Option :value="2">施工总承包单位</Option>
                <Option :value="3">专业分包单位</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <Form-item label="单位名称" prop="Companies[0].Name">
              <Input v-model="project.Companies[0].Name" placeholder="请输入单位名称"></Input>
            </Form-item>
          </Col>
          <Col span="11" offset="1">
            <Form-item label="统一社会信用代码" prop="Companies[0].License">
              <Input v-model="project.Companies[0].License" placeholder="请输入统一社会信用代码"></Input>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <Form-item label="联系人" prop="Companies[0].PrjContactName">
              <Input v-model="project.Companies[0].PrjContactName" placeholder="请输入联系人"></Input>
            </Form-item>
          </Col>
          <Col span="11" offset="1">
            <Form-item label="联系人电话" prop="Companies[0].PrjContactNumber">
              <Input v-model="project.Companies[0].PrjContactNumber" placeholder="请输入联系人电话"></Input>
            </Form-item>
          </Col>
        </Row>
      </div>
      <hr>
      <!-- 总承包 -->
      <div class="company-item">
        <Row>
          <Col span="24">
            <Form-item label="主体类型">
              <Select v-model="project.Companies[1].Type" style="width:240px;" placeholder="施工总承包" disabled>
                <Option :value="1">建设单位</Option>
                <Option :value="2">施工总承包单位</Option>
                <Option :value="3">专业分包单位</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <Form-item label="单位名称" prop="Companies[1].Name">
              <Input v-model="project.Companies[1].Name" placeholder="请输入单位名称"></Input>
            </Form-item>
          </Col>
          <Col span="11" offset="1">
            <Form-item label="统一社会信用代码" prop="Companies[1].License">
              <Input v-model="project.Companies[1].License" placeholder="请输入统一社会信用代码"></Input>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <Form-item label="联系人" prop="Companies[1].PrjContactName">
              <Input v-model="project.Companies[1].PrjContactName" placeholder="请输入联系人"></Input>
            </Form-item>
          </Col>
          <Col span="11" offset="1">
            <Form-item label="联系人电话" prop="Companies[1].PrjContactNumber">
              <Input v-model="project.Companies[1].PrjContactNumber" placeholder="请输入联系人电话"></Input>
            </Form-item>
          </Col>
        </Row>
      </div>
      <hr>
      <!--专业分包 -->
      <div class="company-item">
        <Row>
          <Col span="24">
            <Form-item label="主体类型">
              <Select v-model="project.Companies[2].Type" style="width:240px;" placeholder="专业分包" disabled>
                <Option :value="1">建设单位</Option>
                <Option :value="2">施工总承包单位</Option>
                <Option :value="3">专业分包单位</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <Form-item label="单位名称" prop="Companies[2].Name">
              <Input v-model="project.Companies[2].Name" placeholder="请输入单位名称"></Input>
            </Form-item>
          </Col>
          <Col span="11" offset="1">
            <Form-item label="统一社会信用代码" prop="Companies[2].License">
              <Input v-model="project.Companies[2].License" placeholder="请输入统一社会信用代码"></Input>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <Form-item label="联系人" prop="Companies[2].PrjContactName">
              <Input v-model="project.Companies[2].PrjContactName" placeholder="请输入联系人"></Input>
            </Form-item>
          </Col>
          <Col span="11" offset="1">
            <Form-item label="联系人电话" prop="Companies[2].PrjContactNumber">
              <Input v-model="project.Companies[2].PrjContactNumber" placeholder="请输入联系人电话"></Input>
            </Form-item>
          </Col>
        </Row>
      </div>
      <!--增加专业分包 -->
      <div class="company-item" v-for="(company, index) in project.Companies.filter((com,idx)=>{return idx>2&&com.Type===3})" :key="index">
        <hr>
        <Row>
          <Col span="24">
            <Form-item label="主体类型">
              <Select v-model="project.Companies[index+3].Type" style="width:240px;" placeholder="专业分包" disabled>
                <Option :value="1">建设单位</Option>
                <Option :value="2">施工总承包单位</Option>
                <Option :value="3">专业分包单位</Option>
              </Select>
              <Button type="error" size="small" class="pull-right" @click="removeSubContractor(index)">删除</Button>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <Form-item label="单位名称">
              <Input v-model="project.Companies[index+3].Name" placeholder="请输入单位名称"></Input>
            </Form-item>
          </Col>
          <Col span="11" offset="1">
            <Form-item label="统一社会信用代码">
              <Input v-model="project.Companies[index+3].License" placeholder="请输入统一社会信用代码"></Input>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <Form-item label="联系人">
              <Input v-model="project.Companies[index+3].PrjContactName" placeholder="请输入联系人"></Input>
            </Form-item>
          </Col>
          <Col span="11" offset="1">
            <Form-item label="联系人电话">
              <Input v-model="project.Companies[index+3].PrjContactNumber" placeholder="请输入联系人电话"></Input>
            </Form-item>
          </Col>
        </Row>
      </div>
      <div class="add-moddle">
        <Button type="dashed" long @click="addSubContractor" icon="md-add">新增专业分包</Button>
      </div>
    </Card>
    <div class="padding-top-20"></div>
    <Card>
      <p slot="title">工程担保信息</p>
      <Row>
        <Col span="11">
          <FormItem label="保函类型" prop="GuaranteeType">
            <Select v-model="project.GuaranteeType" placeholder="请选择保函类型">
              <Option :value="0">-----无-----</Option>
              <Option :value="1">农民工工资保函</Option>
              <Option :value="2">工程履约保函</Option>
              <Option :value="3">工程质量保函</Option>
            </Select>
          </FormItem>
        </Col>
        <Col span="11">
          <Form-item label="担保业务员" prop="AgentId">
            {{ project.AgentName }}
            <Button type="primary" @click="toggleAgentSelection">选择担保公司业务员</Button>
          </Form-item>
        </Col>
      </Row>
    </Card>
    <div class="padding-top-20"></div>
    </Form>
    <div class="padding-top-20"></div>
    <Row>
      <Col span="2">
        <Button size="large" @click="returnPath" class="pull-left">返回</Button>
      </Col>
      <Col span="10" offset="5">
        <Button size="large" type="success" long @click="submit" :disabled="!submitEnabled">提交</button>
      </Col>
    </Row>
    <div class="padding-top-20"></div>
    <router-view @returnPerson="setAgent" @returnAdmin="setAdmin"></router-view>
    <userSelector :show="showUserSelector" :type="userSelectorType" @close="showUserSelector=false" @returnPerson="setUserSelected" />
    <mapCoordPicker :show="showMapPicker" mode="single" :picked="(project.Longitude&&project.Latitude)?[project.Longitude, project.Latitude]:null" @close="showMapPicker=false" @select="mapPicked" @select-multi="mapMultiPicked" />
  </div>
</template>

<script>
import pageHeaderView from '../public/PageHeader'
import citySelectList from '../public/CitySelectList'
import userSelector from '../public/UserSelector'
import mapCoordPicker from '../public/MapCoordPicker.vue'
import leftPad from 'leftpad'

export default {
  name: 'project-new',
  components: {
    pageHeaderView,
    citySelectList,
    userSelector,
    mapCoordPicker
  },
  data () {
    return {
      isEdit: false, // 修改模式
      userSelectorType: '项目管理员',
      showUserSelector: false,
      showMapPicker: false,
      CityList: [
        { value: 1, city: '郑州市' }
      ],
      formDynamic: {
        items: [
        ]
      },
      ruleValidate: {
        Name: [
          { required: true, message: '请输入项目名称', trigger: 'blur' }
        ],
        Type: [
          { type: 'number', required: true, message: '请输入项目类型', trigger: 'blur' }
        ],
        Address: [
          { required: true, message: '请输入项目地址', trigger: 'blur' }
        ],
        // BankAccountNumber: [
        //   { required: true, message: '请输入工资专户账号', trigger: 'blur' }
        // ],
        // BankName: [
        //   { required: true, message: '请输入工资专户开户行', trigger: 'blur' }
        // ],
        Tender: [
          { required: true, message: '请输入标段', trigger: 'blur' }
        ],
        PlannedStartDate: [
          { required: true, message: '请选择日期', trigger: 'blur' }
        ],
        PlannedCompletionDate: [
          { required: true, message: '请选择日期', trigger: 'blur' }
        ],
        PrjCoordinate: [
          { required: true, message: '请输入坐标', trigger: 'blur' }
        ],
        PrjContactName: [
          { required: true, message: '请输入项目联系人', trigger: 'blur' }
        ],
        PrjContactNumber: [
          { required: true, message: '请输入项目联系人电话', trigger: 'blur' },
          { type: 'string', pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '手机号码不合法', trigger: 'blur' }
        ],
        ProjectAdminId: [
          { type: 'number', required: true, message: '请输入项目管理员', trigger: 'blur' }
        ],
        'Companies[0].Name': [
          { required: true, message: '请输入建设单位名称', trigger: 'blur' }
        ],
        'Companies[0].License': [
          { required: true, message: '请输入社会信用代码证号', trigger: 'blur' }
        ],
        'Companies[0].PrjContactName': [
          { required: true, message: '请输入联系人', trigger: 'blur' }
        ],
        'Companies[0].PrjContactNumber': [
          { required: true, message: '请输入联系人电话', trigger: 'blur' }
        ],
        'Companies[1].Name': [
          { required: true, message: '请输入施工总承包单位名称', trigger: 'blur' }
        ],
        'Companies[1].License': [
          { required: true, message: '请输入社会信用代码证号', trigger: 'blur' }
        ],
        'Companies[1].PrjContactName': [
          { required: true, message: '请输入联系人', trigger: 'blur' }
        ],
        'Companies[1].PrjContactNumber': [
          { required: true, message: '请输入联系人电话', trigger: 'blur' }
        ],
        // 'Companies[2].Name': [
        //   { required: true, message: '请输入劳务分包单位名称', trigger: 'blur' }
        // ],
        // 'Companies[2].License': [
        //   { required: true, message: '请输入社会信用代码证号', trigger: 'blur' }
        // ],
        // 'Companies[2].PrjContactName': [
        //   { required: true, message: '请输入联系人', trigger: 'blur' }
        // ],
        // 'Companies[2].PrjContactNumber': [
        //   { required: true, message: '请输入联系人电话', trigger: 'blur' }
        // ],
        GuaranteeType: [
          { type: 'number', required: true, message: '请选择担保类型', trigger: 'blur' }
        ],
        AgentId: [
          { type: 'number', required: true, message: '请选择担保公司业务员', trigger: 'blur' }
        ],
        AreaId: [
          { required: true, message: '请选择城市区域', trigger: 'blur' }
        ]
      },
      // optionsStartDate: {},
      // optionsCompletionDate: {},
      project: {
        AreaId: '',                // 城市ID
        Province: '',
        Municipality: '',
        County: '',
        RegistrationNumber: '',    // 项目备案编号（四库一平台）
        ConstructionPermitNumber: '',   // 施工许可证号
        Name: '',                  // 项目名称
        Status: null,
        Type: '',                  // 项目类型
        Address: '',               // 项目地址
        Tender: '',                // 项目标段
        PlannedStartDate: '',      // 项目开始时间
        PlannedCompletionDate: '', // 项目竣工时间
        Longitude: 0,
        Latitude: 0,
        PrjCoordinate: '',
        GuaranteeType: '',         // 保函类型
        Companies: [
          {
            TypeName: '建设单位',   // 建设单位名称
            Type: 1,              // 主体类型
            Name: '',              // 建设单位名称
            PrjContactName: '',    // 项目联系人
            PrjContactNumber: '',  // 项目联系人电话
            MngContactName: '',
            MngContactNumber: '',
            License: ''           // 信用代码
          },
          {
            TypeName: '施工总承包单位',
            Type: 2,
            Name: '',
            PrjContactName: '',
            PrjContactNumber: '',
            MngContactName: '',
            MngContactNumber: '',
            License: ''
          },
          {
            TypeName: '劳务分包单位',
            Type: 3,
            Name: '',
            PrjContactName: '',
            PrjContactNumber: '',
            MngContactName: '',
            MngContactNumber: '',
            License: ''
          }
        ],
        GuarantorId: 1,
        GuarantorName: '',
        AgentId: '',  // 担保公司业务员
        AgentName: '',
        PrjContactName: '',
        PrjContactNumber: '',
        ProjectAdminName: '',
        ProjectAdminId: 0,
        MngContactName: '',
        MngContactNumber: '',
        BankAccountNumber: '',
        BankName: ''
      },
      submitEnabled: true,
      sameContact: false,
      showAdminSelect: false,
      showAgentSelect: false
    }
  },
  mounted () {
    if (this.$route.name === 'ProjectEdit') {
      this.isEdit = true
      this.getProject(this.$route.params.tid).then(() => {
        this.getCityCode(this.project.Province, this.project.Municipality, this.project.County)
      })
    }
    this.$store.dispatch('setPageStore', {
      pageHeader: this.isEdit ? '修改项目' : '新建项目',
      pageDescr: '填写项目信息'
    })
    if (navigator.geolocation && !this.isEdit) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.project.Latitude = position.coords.latitude
          this.project.Longitude = position.coords.longitude
          console.log(position.coords.longitude)
          console.log(position.coords.latitude)
        }
      )
    }
  },
  watch: {
    // 'project.PlannedStartDate': function (newVal) {
    //   this.updateOptionsCompletionDate()
    // }
    // 'project.PlannedCompletionDate': function (newVal) {
    //   this.updateOptionsStartDate()
    // }
    'project.Longitude': function (newVal) {
      this.project.PrjCoordinate = newVal + ',' + this.project.Latitude
    },
    'project.Latitude': function (newVal) {
      this.project.PrjCoordinate = this.project.Longitude + ',' + newVal
    }
  },
  methods: {
    getProject (tid) {
      return new Promise((resolve, reject) => {
        this.submitEnabled = false
        this.$Spin.show()
        this.$Api.Project.getProject(tid || this.$route.params.tid).then((respBody) => {
          this.project.RegistrationNumber = respBody.Project.RegistrationNumber
          this.project.ConstructionPermitNumber = respBody.Project.ConstructionPermitNumber
          this.project.Tid = respBody.Project.Tid
          this.project.Name = respBody.Project.Name
          this.project.Status = respBody.Project.Status
          this.project.Tender = respBody.Project.Tender
          this.project.Type = respBody.Project.Type
          this.project.Address = respBody.Project.Address
          this.project.PlannedStartDate = respBody.Project.PlannedStartDate
          this.project.PlannedCompletionDate = respBody.Project.PlannedCompletionDate
          this.project.GuaranteeType = respBody.Project.GuaranteeType
          this.project.Companies = respBody.Project.Companies
          if (respBody.Project.Companies.length === 2) {
            this.project.Companies.push({
              TypeName: '专业分包单位',
              Type: 3,
              Name: '',
              PrjContactName: '',
              PrjContactNumber: '',
              MngContactName: '',
              MngContactNumber: '',
              License: ''
            })
          }
          this.project.PrjContactName = respBody.Project.PrjContactName
          this.project.PrjContactNumber = respBody.Project.PrjContactNumber
          this.project.ProjectAdminId = respBody.Project.AdminId
          this.project.MngContactName = respBody.Project.MngContactName
          this.project.MngContactNumber = respBody.Project.MngContactNumber
          this.project.AgentId = respBody.Project.AgentId
          this.project.AgentName = respBody.Project.AgentName
          this.project.ProjectAdminId = respBody.Project.ProjectAdminId
          this.project.ProjectAdminName = respBody.Project.ProjectAdminName
          this.project.Longitude = respBody.Project.Longitude / 1000000
          this.project.Latitude = respBody.Project.Latitude / 1000000
          this.project.Province = respBody.Project.Province
          this.project.Municipality = respBody.Project.Municipality
          this.project.County = respBody.Project.County
          this.$Spin.hide()
          this.submitEnabled = true
          resolve()
        })
        .catch(err => {
          this.$Spin.hide()
          this.submitEnabled = false
          this.$Notice.error({
            title: '获取项目信息失败!',
            desc: err.message
          })
          reject(err)
        })
      })
    },
    submit () {
      if (this.isEdit) {
        return this.updateProject()
      } else {
        return this.createProject()
      }
    },
    createProject () {
      this.$refs['projectNewForm'].validate((valid) => {
        if (valid) {
          this.submitEnabled = false
          this.$Spin.show()
          let model = Object.assign({}, this.project)
          model.Latitude = Math.round(this.project.Latitude * 1000000)
          model.Longitude = Math.round(this.project.Longitude * 1000000)
          this.$Api.Project.addProject(model).then(() => {
            this.$Spin.hide()
            this.submitEnabled = true
            this.$router.push({ path: '/projects/list' })
          })
          .catch(err => {
            this.$Spin.hide()
            this.submitEnabled = true
            this.$Notice.error({
              title: '提交失败！',
              desc: err.message
            })
          })
        } else {
          this.$Message.error('表单验证失败!')
        }
      })
    },
    updateProject () {
      this.$refs['projectNewForm'].validate((valid) => {
        if (valid) {
          this.submitEnabled = false
          this.$Spin.show()
          let model = Object.assign({}, this.project)
          model.Latitude = Math.round(this.project.Latitude * 1000000)
          model.Longitude = Math.round(this.project.Longitude * 1000000)
          this.$Api.Project.updateProject(model).then(() => {
            this.$Spin.hide()
            this.submitEnabled = true
            this.$router.push({ path: '/projects/view/' + this.project.Tid })
          })
          .catch(err => {
            this.$Spin.hide()
            this.submitEnabled = true
            this.$Notice.error({
              title: '提交失败！',
              desc: err.message
            })
          })
        } else {
          this.$Message.error('表单验证失败!')
        }
      })
    },
    // updateOptionsStartDate () {
    //   let sDate = this.project.PlannedCompletionDate.valueOf()
    //   this.optionsStartDate = {
    //     disabledDate (date) {
    //       return date && date.valueOf() < sDate
    //     }
    //   }
    // },
    // updateOptionsCompletionDate () {
    //   let cDate = this.project.PlannedStartDate.valueOf()
    //   this.optionsCompletionDate = {}
    //   this.$nextTick(() => {
    //     this.optionsCompletionDate = {
    //       disabledDate (date) {
    //         return date && date.valueOf() < cDate
    //       }
    //     }
    //   })
    // },
    returnPath () {
      this.$router.back()
    },
    selectCity (value) {
      this.project.AreaId = value
      if (value.length > 0) {
        this.project.Province = value.substr(0, 2)
        this.project.Municipality = value.substr(2, 2)
        this.project.County = value.substr(4, 2)
      } else {
        this.project.Province = ''
        this.project.Municipality = ''
        this.project.County = ''
      }
    },
    getCityCode (province, municipality, county) {
      this.project.AreaId = leftPad(province, 2) +
        leftPad(municipality, 2) +
        leftPad(county, 2)
    },
    toggleAgentSelection () {
      this.userSelectorType = '担保公司业务员'
      this.showUserSelector = true
    },
    setUserSelected (user) {
      switch (this.userSelectorType) {
        case '担保公司业务员':
          return this.setAgent(user)
        case '项目管理员':
          return this.setAdmin(user)
        default:
          return null
      }
    },
    setAgent (user) {
      this.project.AgentId = user.Id
      this.project.AgentName = user.Name
    },
    toggleAdminSelection () {
      this.userSelectorType = '项目管理员'
      this.showUserSelector = true
    },
    setAdmin (user) {
      this.project.ProjectAdminId = user.Id
      this.project.ProjectAdminName = user.Name
      this.project.MngContactName = user.Name
      this.project.MngContactNumber = user.MobilePhoneNumber
    },
    getOwner () {
      let owner = {
        TypeName: '建设单位',
        Type: 1,
        Name: '',
        License: '',
        ContactName: '',
        ContactNumber: ''
      }
      let ownerDB = this.project.Companies.filter((item) => {
        return item.Type === 1
      })[0]
      if (ownerDB) {
        owner = ownerDB
      }
      this.project.Companies.push(owner)
      return owner
    },
    getContractor () {
      let contractor = {
        TypeName: '施工总承包单位',
        Type: 2,
        Name: '',
        License: '',
        ContactName: '',
        ContactNumber: ''
      }
      let contractorDB = this.project.Companies.filter((item) => {
        return item.Type === 2
      })[0]
      if (contractorDB) {
        contractor = contractorDB
      }
      this.project.Companies.push(contractor)
      return contractor
    },
    getSubContractor () {
      let subContractor = [{
        TypeName: '专业分包单位',
        Type: 3,
        Name: '',
        License: '',
        ContactName: '',
        ContactNumber: ''
      }]
      let subContractorDB = this.project.Companies.filter((item) => {
        return item.Type === 3
      })
      if (subContractorDB) {
        subContractor = subContractorDB
      }
      this.project.Companies.push(subContractor)
      return subContractor
    },
    existOwner () {
      let ownerDB = this.project.Companies.filter((item) => {
        return item.Type === 1
      })
      if (ownerDB) {
        return true
      } else {
        return false
      }
    },
    existContractor () {
      let ContractorDB = this.project.Companies.filter((item) => {
        return item.Type === 2
      })
      if (ContractorDB) {
        return true
      } else {
        return false
      }
    },
    existSubContractor () {
      let SubContractorDB = this.project.Companies.filter((item) => {
        return item.Type === 3
      })
      if (SubContractorDB) {
        return true
      } else {
        return false
      }
    },
    addSubContractor () {
      this.project.Companies.push({
        TypeName: '专业分包单位',
        Type: 3,
        Name: '',
        License: '',
        PrjContactName: '',
        PrjContactNumber: ''
      })
    },
    removeSubContractor (index) {
      this.project.Companies.splice(index, 1)
    },
    setPlannedStartDate (date) {
      this.project.PlannedStartDate = date
    },
    setPlannedCompletionDate (date) {
      this.project.PlannedCompletionDate = date
    },
    mapMultiPicked (points) {
      console.log(points)
    },
    mapPicked (point, address) {
      console.log(point, address)
      if (point && point instanceof Array && point.length === 2) {
        this.project.Longitude = point[0]
        this.project.Latitude = point[1]
      }
      this.showMapPicker = false
      if (address && address !== this.project.address) {
        this.$Modal.confirm({
          title: '更新项目地址',
          content: '<p>检测到项目地址变化，要更新项目地址么？</p>',
          onOk: () => {
            this.formValidate = {
              Type: 0,
              PrjNo: '',
              PrjName: '',
              Tenderee: '',
              TenderCreditCode: '',
              City: '',
              ProjectTypeId: null,
              BOD: '',
              BODDate: '',
              BODTime: '',
              BiddingValidDue: '',
              BidBond: 0,
              Code: null
            }
            this.project.Address = address
          }
        })
      }
    }
  }
}
</script>

<style scoped>
fieldset {
  min-width: 0;
  padding: 5px;
  margin: 0;
  border: 1px solid #ccc;
  background: #f4f4f4;
  margin-bottom: 20px;
}

legend {
  /*background: #fff;*/
  border-bottom: none;
  width: auto;
}

hr {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #fff;
  margin: 15px -16px;
}

.form-group label {
  color: #777;
}

.form-control-feedback {
  margin-right: 15px;
}

.subcontractor {
  position: relative;
}

.subcontractor-remove {
  position: absolute;
  top: 22px;
  right: 15px;
  z-index: 5;
}

.layout-content-main {
  padding: 10px;
}

.card-content {
  padding: 20px;
  width: 96%;
}
/*card-content*/

.ivu-form-item {
  margin-bottom: 20px !important;
}

/*card卡片input间距*/

.ivu-row-flex {
  margin-bottom: 12px;
}

.card-content1 .ivu-row-flex,
.card-content2 .ivu-row-flex {
  margin-bottom: 6px;
}
.card-content1 .ivu-form-item-content {
  float: none !important;
  padding-right: 100px;
  width: 100%;
}
.card-content2 .ivu-form-item-content{
  float: none !important;
  padding-right: 100px;
  width: 100%;
}
.add .ivu-form-item-content{
  padding: 0 !important;
}
.rem .ivu-form-item-content{
  padding-right: 100px !important; 
}
.rem1{
  position: absolute !important;
  top:34px;
  right:0;
}
.add-moddle{
  width: 100%;
  text-align: center;
}
.add-moddle button{
  width: 50%;
  margin: auto;
}
</style>
